.gallery-parent {
	background: url('../../assets/images/bg.webp') !important;
	/* FIXME: fix the background image issue */
	background-size: cover;
	background-repeat: repeat-y;
	background-position: center;
	color: black;

	display: flex;
	gap: 16px;
	flex-flow: row wrap;
	justify-content: center;
}
