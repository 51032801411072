@font-face {
	font-family: Angella-white;
	src: url('../../assets/fonts/AngellaWhite.otf');
}
@font-face {
	font-family: TT-Chocolate;
	src: url('../../assets/fonts/TTChocolates.otf');
}
@font-face {
	font-family: Canva-Sans;
	src: url('../../assets/fonts/CanvaSans.otf');
}
@font-face {
	font-family: Caveat;
	src: url('../../assets/fonts/Caveat.otf');
}
@font-face {
	font-family: DancingScript;
	src: url('../../assets/fonts/DancingScript.otf');
}

.parent {
	background: url('../../assets/images/bg.webp') !important;
	/* FIXME: fix the background image issue */
	background-size: cover;
	background-repeat: repeat-y;
	background-position: center;
	color: black;
}

.angella-font {
	font-family: Angella-white;
}
.tt-chocolate-font {
	font-family: TT-Chocolate;
}
.caveat {
	font-family: Caveat;
}
.dancing-script {
	font-family: DancingScript;
}
.hide-in-mobile {
	@media (max-width: 720px) {
		display: none;
	}
}

.gallery-image {
	height: 200px;
	width: 200px;
	object-fit: contain;
	border-radius: 10px;
}

/* This sets the style for section html tag */
section {
	text-align: center;
}

#wedding-info-container {
	margin: 176px 0;
	padding: 0 16px;
	gap: 16px;

	@media (max-width: 480px) {
		margin: 112px 0;
	}
}

#wedding-info-container p:nth-child(1) {
	font-family: Angella-white;
	font-size: 80px;

	@media (max-width: 480px) {
		font-size: 64px;
	}
}
#wedding-info-container p:nth-child(2) {
	font-family: Angella-white;
	font-size: 40px;

	@media (max-width: 480px) {
		font-size: 36px;
	}
}
#wedding-info-container div p:nth-child(2) {
	font-family: DancingScript;
	font-size: 24px;
	white-space: pre-line;
	background-image: url('../../assets/images/txtbg.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;

	@media (max-width: 480px) {
		font-size: 18px;
	}
}

#our-story p {
	margin: 32px 0 0;
	font-size: 48px;
	font-family: Angella-white;
	text-decoration: underline;
}
#our-story video {
	color: white;
	background-color: #f8cba9;
	border-radius: 10px;
	width: 95%;
	@media (max-width: 480px) {
	}
}

#wedding-ritual-section {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 16px;
	width: auto;
	gap: 32px;

	@media (max-width: 480px) {
		gap: 16px;
	}
}

#wedding-ritual-section div:first-child {
	display: inline-flex;
	flex-direction: column;
	width: fit-content;
}

#wedding-ritual-section div p:nth-child(1) {
	text-decoration: underline;
	align-self: flex-start;
	margin: 0;
}

#wedding-ritual-section div p:nth-child(2) {
	align-self: flex-end;
	font-family: TT-Chocolate;
	font-weight: bold;
	text-transform: uppercase;
}

#ritual-section {
	margin: 16px;
	display: flex;
	flex-direction: column;
}

#ritual-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 32px;
}

#ritual-container:nth-child(odd) {
	flex-direction: row;

	@media (max-width: 480px) {
		flex-direction: column;
	}
}
#ritual-container:nth-child(even) {
	flex-direction: row-reverse;

	@media (max-width: 480px) {
		flex-direction: column;
	}
}

#ritual-container img {
	max-height: 200px;
	max-width: 200px;
	border-radius: 5px;
	resize: contain;
}

.ritual-message {
	font-size: 32px;
	@media (max-width: 500px) {
		font-size: 24px;
	}
}

#venue-details-section p:first-child {
	font-family: Angella-white;
	font-size: 56px;
}

#venue-details-section a {
	color: #b88b55;
	text-decoration: none;
}

#venue-container {
	display: inline-flex;
}
#venue-container img {
	height: 32px;
	width: 32px;
	margin-top: 16px;
}
#venue-container p {
	font-family: Angella-white;
	font-size: 56px;
}

#gallery-section a div {
	font-family: Angella-white;
	font-size: 56px;
	display: inline-flex;
	align-items: center;
}

#gallery-section a div img {
	height: 32px;
	width: 32px;
}

#thanks-section {
	display: flex;
	justify-content: center;
	align-items: baseline;
}

#thanks-for-visiting-text {
	font-size: 24px;
	color: rgba(0, 0, 0, 0.5);
	padding: 0 10px;

	@media (max-width: 480px) {
		font-size: 20px;
	}
}

#thanks-for-visiting-text::before {
	background-color: #000;
	content: '';
	display: inline-block;
	height: 1px;
	position: relative;
	vertical-align: middle;
	width: 50%;
	right: 0.5em;
	margin-left: -50%;
}

#thanks-for-visiting-text::after {
	background-color: #000;
	content: '';
	display: inline-block;
	height: 1px;
	position: relative;
	vertical-align: middle;
	width: 50%;
	left: 0.5em;
	margin-right: -50%;
}
